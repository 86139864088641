<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1 mt-1"
        >


            <div class="pb-2 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="7"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mr-1 mb-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <infinity-scroll v-if="displayed_table == 'patterns'"
                                         style="    min-width: 119px;"
                                        class="mr-1 mb-1 form-control"
                                         selected-prop="id"
                                         :multiple="false"
                                         url="/pattern_category"
                                         :placeholder="$t('label_category')"

                                         v-model="filterData.category"

                        >
                            <template #label="{item}">{{ item.name }}</template>

                        </infinity-scroll>
                        <b-form-radio v-for="type in tableTypes"
                                      class="mr-1 mb-1"
                                      v-model="displayed_table"
                                      button
                                      size="md"
                                      button-variant="outline-primary"
                                      :value="type.alias"
                        >
                            <span style="white-space: nowrap;">{{$t(type.label)}}</span>
                        </b-form-radio>



                    </b-col>


                    <b-col class="ml-auto text-right"  cols="12" md="4">


                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 search--select mb-1"
                                :placeholder="$t('label_search')+'...'"
                        />
                    </b-col>


                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table

                        :ref="PREFIX+'_TABLE_pay'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <template #cell(yourwritings_actions)="data">
                        <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item disabled>
                                <div class="divider">
                                    <div class="divider-text">{{ $t('label_action') }}</div>
                                </div>

                            </b-dropdown-item>


                            <b-dropdown-item @click="deleteYourWriting(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>


                        </b-dropdown>

                    </template>

                    <template #cell(id_pattern)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(pattern_category)="data">
                        {{ (data.item.pattern && data.item.pattern.category)? data.item.pattern.category.name : '--' }}
                    </template>
                    <template #cell(m_pattern_category)="data">
                        {{ data.item.category?data.item.category.name: '--'}}
                    </template>
                    <template #cell(m_pattern_name)="data">
                        {{ data.item.pattern_name }}
                    </template>
                    <template #cell(pattern_name)="data">
                        {{ data.item.pattern? data.item.pattern.pattern_name : '' }}
                    </template>
                    <template #cell(postedtime)="data">
                        {{ formatDate(data.item.postedtime, 'DD-MM-YYYY HH:mm', 'unix') }}
                    </template>
                    <template #cell(m_pattern_description)="data">
                        {{ data.item.pattern_description }}
                    </template>
                    <template #cell(pattern_description)="data">
                        {{ data.item.pattern? data.item.pattern.pattern_description : '' }}
                    </template>
                    <template #cell(report_file)="data">
                        <a v-if="data.item.file_pdf" v-b-tooltip.hover.top="data.item.file_pdf"
                                class="mr-1" @click="previewFile($event,  MODULE_PREFIX + '_' +PREFIX + 's/download/', Object.assign(data.item, {id: data.item.id, file_name: data.item.file_pdf, params:{type:'pdf'}}))" href="#">
                            <feather-icon
                                    size="18"
                                    icon="FileIcon"
                            />
                        </a>
                        <a v-if="data.item.file_docx"
                           @click="previewFile($event,  MODULE_PREFIX + '_' +PREFIX + 's/download/', Object.assign(data.item, {id: data.item.id, file_name: data.item.file_docx, params:{type:'docx'}}))" href="#">
                            <feather-icon
                                    size="18"
                                    icon="FileTextIcon"
                            />
                        </a>
                    </template>
                    <template #cell(patterns_actions)="data">

                        <b-button style="min-width: 100px" variant="outline-primary" size="sm"
                                  @click="generateYourPattern(data.item.id_pattern)">
                            {{ $t('label_generate') }}
                        </b-button>
                    </template>


                </b-table>
            </div>
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton, BAlert,BCard, BTooltip, BFormInput, BFormRadio, VBTooltip,

    } from 'bootstrap-vue'
    import {trSortable} from '@src/utils/directives'

    import vSelect from 'vue-select'

    import  infinityScroll from '@/views/components/infinityScroll'

    import {AGREEMENT_DYNAMIC_STORE_NAME, DEAL_PREFIX as MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton, BAlert, BCard,  BTooltip,BFormInput, BFormRadio,
            vSelect,
            infinityScroll
        },
        props: ['moduleItem'],
        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                AGREEMENT_DYNAMIC_STORE_NAME,
                PREFIX: 'pattern',
                MODULE_PREFIX,

                tableTypes: [
                    {label:'label_pattern_writings', alias:'patterns'},
                    {label:'label_pattern_your_writings', alias:'yourwritings'}
                ],
                module_id: 0,
                tabItem:false,

                columnsDefs_yourwritings: [
                    {label: 'label_№', key: 'id_pattern',sortable: true},
                    {label: 'label_category', key: 'pattern_category', sortable: false},
                    {label: 'label_pattern_name', key: 'pattern_name', sortable: false},
                    {label: 'label_description', key: 'pattern_description', sortable: false},
                    {label: 'label_creating_date', key: 'postedtime', sortable: false},
                    {label: 'label_download', key: 'report_file', sortable: false},
                    {label: 'label_action', key: 'yourwritings_actions'}
                ],
                columnsDefs_patterns: [
                    {label: 'label_№', key: 'id_pattern',sortable: true},
                    // {label: '#', key: 'checkboxes'},
                    {label: 'label_category', key: 'm_pattern_category', sortable: false},
                    {label: 'label_pattern_name', key: 'm_pattern_name', sortable: false},
                    {label: 'label_description', key: 'm_pattern_description', sortable: false},
                    {label: 'label_creating_date', key: 'postedtime', sortable: false},
                    {label: 'label_action', key: 'patterns_actions'}
                ],


                displayed_table: '',

                columnsDefs:[],
                tableData:{},

                isBusy:false,
                tableItems: [],
                tableTotal: 0,
                tableData_default: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData:{
                    category:null
                },
                categories:[],

            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (newVal, Old) {
                    this.refreshDataTable();
                },
                deep: true
            },
            displayed_table: function(cur_table){

                this.displayTable(cur_table);
            },
          moduleItem: {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.refreshDataTable();
              }
            }
          }
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {


            refreshDataTable: function () {
               this.isBusy = true;
               let url = '';

               let params = {
                   length: this.tableData.perPage,
                   start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                   search: this.tableData.searchQuery,
                   sort_by: this.tableData.sortBy,
                   sort_desc: this.tableData.sortDesc? true : null,
               };
               if(this.displayed_table == 'yourwritings') {
                        url = '/'+this.MODULE_PREFIX+'_patterns';
                        // params.extra_search = 'declarative_time';
                   params.deal_id = this.moduleItem.id;
               } else if(this.displayed_table == 'patterns'){
                   params.id_pattern_category = this.filterData.category;
                   url = '/pattern';
               }  else {
                   return false;
               }


                this.async('get', url , {
                    params: params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                this.isBusy = false;
                });
            },


            deleteYourWriting(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/'+this.MODULE_PREFIX+'_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },

            generateYourPattern(id){
                this.async('post', '/'+this.MODULE_PREFIX+'_patterns/generate/' + id , {

                        deal_id: this.moduleItem.id,
                        pattern_id: id

                }, function (resp) {
                    this.displayTable('yourwritings');
                });

            },
            displayTable(table_name){

                if(table_name == 'yourwritings'){
                    this.displayed_table = 'yourwritings';
                    this.columnsDefs = this.columnsDefs_yourwritings;
                } else if(table_name == 'patterns'){
                    this.columnsDefs = this.columnsDefs_patterns;
                    this.displayed_table = 'patterns';
                } else {
                    return;
                }

                this.tableData = Object.assign({}, this.tableData_default);

            },

            getFileName(file){
                return file.display_name + '.' + file.file_name.split('.').pop();
            }

        },

        created() {
            let self = this;
            this.module_id = this.$router.currentRoute.params.id;

            this.columnsDefs_patterns = this.columnsDefs_patterns.filter(function (obj) {
                if ((obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX+'.tab_' + self.PREFIX)) {
                    return false;
                }
                return true;

            });
            this.columnsDefs_yourwritings = this.columnsDefs_yourwritings.filter(function (obj) {
                if (( obj.key == 'actions') && !self.$can('edit', self.MODULE_PREFIX+'.tab_' + self.PREFIX)) {
                    return false;
                }
                return true;

            });

            this.displayTable('yourwritings');


        },
    }
</script>
