<template>
  <div>
    <template>
      <b-row style="margin-top:-20px">
        <b-col

          cols="12"
          md="3"
          class=" mb-1 ml-auto"
        >
          <div class="mb-2">
            <label>{{ $t('label_deal_status') }}</label>
            <v-select
              ref="status-select"
              :disabled="!$can('edit', MODULE_PREFIX+'.tab_details')"
              v-model="dealStatusId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statuses"
              class="w-100"
              :placeholder="$t('label_select')"
              :reduce="val => val.id"
              @input="changeContactStatus"
            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>
          </div>

        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class=" mb-1"
        >
          <b-table-simple
            class="table-details"
            responsive
            no-border-collapse
          >
            <b-tbody>
              <b-tr>
                <b-td width="40%">
                  {{ $t('label_deal_number') }}
                </b-td>
                <b-td class="actions">
                  <span v-if="editedItem.number">{{ editedItem.number }}</span>
                  <span v-else-if="editedItem.deal_number">{{ editedItem.deal_number }}</span>
                  <span v-else-if="editedItem.agreement">{{ editedItem.agreement.deal_number }}</span>
                </b-td>
              </b-tr>
              <template v-if="editedItem.deal_type && editedItem.deal_type.name_alias == 'traffic_accident'">
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_date_of_event') }}
                  </b-td>
                  <b-td>
                    {{ formatDate(editedItem.traffic_accident_date, 'DD.MM.YYYY', 'YYYY-MM-DD') }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_accident_submission_date') }}
                  </b-td>
                  <b-td>
                    {{ formatDate(editedItem.traffic_accident_submission_date, 'DD.MM.YYYY', 'YYYY-MM-DD') }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_victim_vehicle_brand') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_victim_vehicle_brand }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_victim_vehicle_registration_number') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_victim_registration_number }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_accident_country') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.accidentCountry ? editedItem.accidentCountry.countryName : '' }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_accident_address') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_address }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_accident_protocol_number') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_victim_protocol_number }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_cancellary_number') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_victim_cancellary_number }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_value_of_claims') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.value_of_claims }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_description_of_claims') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.description_of_claims }}
                  </b-td>
                </b-tr>
                <b-tr v-if="allowNoteDetails">
                  <b-td width="40%">
                    {{ $t('label_damager_insurance_company_name') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_damager_insurance_company_name }}
                  </b-td>
                </b-tr>
                <b-tr v-if="allowNoteDetails">
                  <b-td width="40%">
                    {{ $t('label_damager_insurance_company_phone') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_damager_insurance_company_phone }}
                  </b-td>
                </b-tr>
                <b-tr v-if="allowNoteDetails">
                  <b-td width="40%">
                    {{ $t('label_damager_insurance_company_deal_number') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_damager_insurance_company_deal_number }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_vehicle_brand') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_damager_vehicle_brand }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_vehicle_registration_number') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_damager_vehicle_registration_number }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_accident_other_damages') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_other_damages }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td width="40%">
                    {{ $t('label_is_police_present') }}
                  </b-td>
                  <b-td>
                    {{ editedItem.traffic_accident_is_police_present == 1? $t('label_yes') : $t('label_no') }}
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class=" mb-1"
        >
          <b-table-simple
            class="table-details"
            responsive
            no-border-collapse
          >
            <b-tbody>
              <b-tr>
                <b-td width="40%">
                  {{ $t('label_consultant_leading_case') }}
                </b-td>
                <b-td>
                  <router-link
                    v-if="editedItem.consultant && $can('view', 'consultant.tab_details')"
                    :to="{ name: 'consultant-details', params: { id: editedItem.consultant.id } }"
                  >{{ editedItem.consultant? editedItem.consultant.name : '' }}</router-link>
                  <span v-else>{{ editedItem.consultant? editedItem.consultant.name : '' }}</span>
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_recommended') }}
                </b-td>
                <b-td>
                  <router-link
                    v-if="editedItem.agent && $can('view', 'agent.tab_details')"
                    :to="{ name: 'agent-details', params: { id: editedItem.agent.id } }"
                  >{{ editedItem.agent? editedItem.agent.name : '' }}</router-link>
                  <span v-else>{{ editedItem.agent? editedItem.agent.name : '' }}</span>
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails || isSolicitor">
                <b-td width="40%">
                  {{ $t('label_solicitors') }}
                </b-td>
                <b-td>

                  <template
                    v-for="(solicitor, ind) in editedItem.solicitors"
                    v-if="$can('view', 'solicitor.tab_details')"
                  >
                    <router-link
                      :key="'sol_'+solicitor.id"
                      :to="{ name: 'solicitor-details', params: { id: solicitor.id } }"
                    >{{ solicitor.name }}
                    </router-link>
                    {{ ((ind+1 == editedItem.solicitors.length)? '' : ' | ') }}
                  </template>
                  <template
                    v-for="solicitor,ind in editedItem.solicitors"
                    v-else
                  >
                    {{ solicitor.name }} {{ ((ind+1 == editedItem.solicitors.length)? '' : ' | ') }}
                  </template>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td width="40%">
                  {{ $t('label_company_name') }}
                </b-td>
                <b-td class="actions">
                  {{ editedItem.firm_name }}
                </b-td>
              </b-tr>
              <b-tr v-if="!isSolicitor">
                <b-td width="40%">
                  {{ $t('label_nip') }}
                </b-td>
                <b-td class="actions">
                  {{ editedItem.nip }}
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_address_from_agreement') }}
                </b-td>

                <b-td class="actions">
                  {{ editedItem.address_agreement }}

                  <template v-if="editedItem.changedFields && editedItem.changedFields.address_agreement">

                    <feather-icon
                      id="popover-address-from-agreement"
                      icon="AlertCircleIcon"
                      color="green"
                      size="20"
                      class="annex-warning tooltip-html"
                    >i
                    </feather-icon>

                    <b-popover
                      class="bg-info"
                      target="popover-address-from-agreement"
                      triggers="hover"
                      placement="top"
                      variant="primary"
                    >
                      <template #title>
                        <span>{{ $t('label_address_from_agreement') }}</span>
                      </template>
                      <div class="tooltip-content">
                        <div v-for="inf in editedItem.changedFields.address_agreement"><span
                          class="badge badge-light-warning"
                        >{{ formatDate(inf.created_at,
                            'DD.MM.YYYY', 'unix') + ': ' }}</span> {{ $t('label_changed') + ' ' +
                        $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                        $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"' }}
                        </div>
                      </div>
                    </b-popover>
                  </template>

                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_email_address') }}
                </b-td>

                <b-td class="actions">
                  {{ editedItem.email }}

                  <template v-if="editedItem.changedFields && editedItem.changedFields.email">

                    <feather-icon
                      id="popover-email"
                      icon="AlertCircleIcon"
                      color="green"
                      size="20"
                      class="annex-warning tooltip-html"
                    >i
                    </feather-icon>

                    <b-popover
                      class="bg-info"
                      target="popover-email"
                      triggers="hover"
                      placement="top"
                      variant="primary"
                    >
                      <template #title>
                        <span>{{ $t('label_email_address') }}</span>
                      </template>
                      <div class="tooltip-content">
                        <div v-for="inf in editedItem.changedFields.email"><span
                          class="badge badge-light-warning"
                        >{{ formatDate(inf.created_at,
                            'DD.MM.YYYY', 'unix') + ': ' }}</span> {{ $t('label_changed') + ' ' +
                        $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                        $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"' }}
                        </div>
                      </div>
                    </b-popover>
                  </template>

                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_phone') }}
                </b-td>

                <b-td class="actions">
                  {{ editedItem.phone }}

                  <template v-if="editedItem.changedFields && editedItem.changedFields.phone">

                    <feather-icon
                      id="popover-phone"
                      icon="AlertCircleIcon"
                      color="green"
                      size="20"
                      class="annex-warning tooltip-html"
                    >i
                    </feather-icon>

                    <b-popover
                      class="bg-info"
                      target="popover-phone"
                      triggers="hover"
                      placement="top"
                      variant="primary"
                    >
                      <template #title>
                        <span>{{ $t('label_phone') }}</span>
                      </template>
                      <div class="tooltip-content">
                        <div v-for="inf in editedItem.changedFields.phone"><span
                          class="badge badge-light-warning"
                        >{{ formatDate(inf.created_at,
                            'DD.MM.YYYY', 'unix') + ': ' }}</span> {{ $t('label_changed') + ' ' +
                        $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                        $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"' }}
                        </div>
                      </div>
                    </b-popover>
                  </template>

                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="50%">
                  {{ $t('label_client_bank_account_number') }}
                </b-td>
                <b-td class="actions">
                  {{ accountNumber }}
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_swift_number') }}
                </b-td>
                <b-td class="actions">
                  {{ editedItem.swift_number }}
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_approver_firstname') }}
                </b-td>
                <b-td class="actions">

                  {{ editedItem.first_name }}
                </b-td>
              </b-tr>
              <b-tr v-if="allowNoteDetails && !isSolicitor">
                <b-td width="40%">
                  {{ $t('label_approver_lastname') }}
                </b-td>
                <b-td class="actions">

                  {{ editedItem.last_name }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td width="40%">
                  {{ $t('label_deal_type') }}
                </b-td>
                <b-td>
                  {{ editedItem.deal_type? editedItem.deal_type.name : '' }}
                </b-td>
              </b-tr>
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_correspondence_address') }}-->
              <!--              </b-td>-->

              <!--              <b-td class="actions">-->
              <!--                {{ editedItem.address_correspondence }}-->

              <!--                <template-->
              <!--                  v-if="editedItem.changedFields && editedItem.changedFields.address_correspondence"-->
              <!--                >-->

              <!--                  <feather-icon-->
              <!--                    id="popover-correspondence-address"-->
              <!--                    icon="AlertCircleIcon"-->
              <!--                    color="green"-->
              <!--                    size="20"-->
              <!--                    class="annex-warning tooltip-html"-->
              <!--                  >i-->
              <!--                  </feather-icon>-->

              <!--                  <b-popover-->
              <!--                    class="bg-info"-->
              <!--                    target="popover-correspondence-address"-->
              <!--                    triggers="hover"-->
              <!--                    placement="top"-->
              <!--                    variant="primary"-->
              <!--                  >-->
              <!--                    <template #title>-->
              <!--                      <span>{{ $t('label_correspondence_address') }}</span>-->
              <!--                    </template>-->
              <!--                    <div class="tooltip-content">-->
              <!--                      <div v-for="inf in editedItem.changedFields.address_correspondence"><span-->
              <!--                        class="badge badge-light-warning"-->
              <!--                      >{{ formatDate(inf.created_at,-->
              <!--                                     'DD.MM.YYYY', 'unix') + ': ' }}</span> {{ $t('label_changed') + ' ' +-->
              <!--                                       $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +-->
              <!--                                       $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"' }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </b-popover>-->
              <!--                </template>-->

              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_type_of_ageement') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->
              <!--                &lt;!&ndash;<?php echo $contact_data->deal_name; ?>&ndash;&gt;-->
              <!--                {{ editedItem.type? $t('label_'+editedItem.type.name_alias) : '' }}-->
              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_subject_of_the_order') }}-->
              <!--              </b-td>-->

              <!--              <b-td class="actions">-->
              <!--                {{ editedItem.order_subject? editedItem.order_subject.name : '' }}-->

              <!--                <template v-if="editedItem.changedFields && editedItem.changedFields.order_subject">-->

              <!--                  <feather-icon-->
              <!--                    id="popover-order-subject"-->
              <!--                    icon="AlertCircleIcon"-->
              <!--                    color="green"-->
              <!--                    size="20"-->
              <!--                    class="annex-warning tooltip-html"-->
              <!--                  >i-->
              <!--                  </feather-icon>-->

              <!--                  <b-popover-->
              <!--                    class="bg-info"-->
              <!--                    target="popover-order-subject"-->
              <!--                    triggers="hover"-->
              <!--                    placement="top"-->
              <!--                    variant="primary"-->
              <!--                  >-->
              <!--                    <template #title>-->
              <!--                      <span>{{ $t('label_subject_of_the_order') }}</span>-->
              <!--                    </template>-->
              <!--                    <div class="tooltip-content">-->
              <!--                      <div v-for="inf in editedItem.changedFields.order_subject"><span-->
              <!--                        class="badge badge-light-warning"-->
              <!--                      >{{ formatDate(inf.created_at,-->
              <!--                                     'DD.MM.YYYY', 'unix') + ': ' }}</span> {{ $t('label_changed') + ' ' +-->
              <!--                                       $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +-->
              <!--                                       $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"' }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </b-popover>-->
              <!--                </template>-->

              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_agreement_description') }}-->
              <!--              </b-td>-->

              <!--              <b-td class="actions">-->
              <!--                {{ editedItem.agreement_desc }}-->

              <!--                <template-->
              <!--                  v-if="editedItem.changedFields && editedItem.changedFields.agreement_description"-->
              <!--                >-->

              <!--                  <feather-icon-->
              <!--                    id="popover-agreement-description"-->
              <!--                    icon="AlertCircleIcon"-->
              <!--                    color="green"-->
              <!--                    size="20"-->
              <!--                    class="annex-warning tooltip-html"-->
              <!--                  >i-->
              <!--                  </feather-icon>-->

              <!--                  <b-popover-->
              <!--                    class="bg-info"-->
              <!--                    target="popover-agreement-descriptiont"-->
              <!--                    triggers="hover"-->
              <!--                    placement="top"-->
              <!--                    variant="primary"-->
              <!--                  >-->
              <!--                    <template #title>-->
              <!--                      <span>{{ $t('label_agreement_description') }}</span>-->
              <!--                    </template>-->
              <!--                    <div class="tooltip-content">-->
              <!--                      <div v-for="inf in editedItem.changedFields.agreement_description">-->
              <!--                        v$t('label_changed') + ' ' +-->
              <!--                        $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +-->
              <!--                        $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </b-popover>-->
              <!--                </template>-->

              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_deal_type') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->
              <!--                {{ editedItem.deal_type? editedItem.deal_type.name : '' }}-->
              <!--              </b-td>-->
              <!--            </b-tr>-->

              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_consultant_leading_case') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->
              <!--                <router-link-->
              <!--                  v-if="editedItem.consultant && $can('view', 'consultant.tab_details')"-->
              <!--                  :to="{ name: 'consultant-details', params: { id: editedItem.consultant.id } }"-->
              <!--                >{{ editedItem.consultant? editedItem.consultant.name : '' }}</router-link>-->
              <!--                <span v-else>{{ editedItem.consultant? editedItem.consultant.name : '' }}</span>-->
              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_recommended') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->
              <!--                <router-link-->
              <!--                  v-if="editedItem.agent && $can('view', 'agent.tab_details')"-->
              <!--                  :to="{ name: 'agent-details', params: { id: editedItem.agent.id } }"-->
              <!--                >{{ editedItem.agent? editedItem.agent.name : '' }}</router-link>-->
              <!--                <span v-else>{{ editedItem.agent? editedItem.agent.name : '' }}</span>-->
              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_accountants') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->

              <!--                <template-->
              <!--                  v-for="accountant,ind in editedItem.accountants"-->
              <!--                  v-if="$can('view', 'accountant.tab_details')"-->
              <!--                >-->
              <!--                  <router-link-->
              <!--                    :key="'acc_'+accountant.id"-->
              <!--                    :to="{ name: 'accountant-details', params: { id: accountant.id } }"-->
              <!--                  >{{ accountant.name }}-->
              <!--                  </router-link>-->
              <!--                  {{ ((ind+1 == editedItem.accountants.length)? '' : ' | ') }}-->
              <!--                </template>-->
              <!--                <template-->
              <!--                  v-for="accountant,ind in editedItem.accountants"-->
              <!--                  v-else-->
              <!--                >-->
              <!--                  {{ accountant.name }} {{ ((ind+1 == editedItem.accountants.length)? '' : ' | ') }}-->
              <!--                </template>-->
              <!--              </b-td>-->
              <!--            </b-tr>-->
              <!--            <b-tr>-->
              <!--              <b-td width="40%">-->
              <!--                {{ $t('label_solicitors') }}-->
              <!--              </b-td>-->
              <!--              <b-td>-->

              <!--                <template-->
              <!--                  v-for="solicitor,ind in editedItem.solicitors"-->
              <!--                  v-if="$can('view', 'solicitor.tab_details')"-->
              <!--                >-->
              <!--                  <router-link-->
              <!--                    :key="'sol_'+solicitor.id"-->
              <!--                    :to="{ name: 'solicitor-details', params: { id: solicitor.id } }"-->
              <!--                  >{{ solicitor.name }}-->
              <!--                  </router-link>-->
              <!--                  {{ ((ind+1 == editedItem.solicitors.length)? '' : ' | ') }}-->
              <!--                </template>-->
              <!--                <template-->
              <!--                  v-for="solicitor,ind in editedItem.solicitors"-->
              <!--                  v-else-->
              <!--                >-->
              <!--                  {{ solicitor.name }} {{ ((ind+1 == editedItem.solicitors.length)? '' : ' | ') }}-->
              <!--                </template>-->
              <!--              </b-td>-->
              <!--            </b-tr>-->
              <b-tr v-if="!isSolicitor">
                <b-td width="40%">
                  {{ $t('label_secretaries') }}
                </b-td>
                <b-td>

                  <template
                    v-for="secretary,ind in editedItem.secretaries"
                    v-if="$can('view', 'secretary.tab_details')"
                  >
                    <router-link
                      :key="'acc_'+secretary.id"
                      :to="{ name: 'secretary-details', params: { id: secretary.id } }"
                    >{{ secretary.name }}
                    </router-link>
                    {{ ((ind+1 == editedItem.secretaries.length)? '' : ' | ') }}
                  </template>
                  <template
                    v-for="secretary,ind in editedItem.secretaries"
                    v-else
                  >
                    {{ secretary.name }} {{ ((ind+1 == editedItem.secretaries.length)? '' : ' | ') }}
                  </template>
                </b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>


        </b-col>
      </b-row>

      <b-row clss="mt-3" v-if="!isSolicitor">
        <b-col cols="12">

          <b-table-simple
            v-if="editedItem.annexes && editedItem.annexes.length > 0"
            caption-top
            bordered
            small
            responsive
            no-border-collapse
          >
            <caption><b>{{ $t('label_annexes') }}</b></caption>
            <b-thead head-variant="info">
              <b-tr>
                <b-th>{{ $t('label_annex_adding_date') }}</b-th>
                <b-th>{{ $t('label_annex_effective_date_from') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>

              <b-tr v-for="annex in editedItem.annexes">
                <b-td>
                  {{ formatDate(annex.created_at, 'DD.MM.YYYY', 'unix') }}
                </b-td>
                <b-td>
                  {{ formatDate(annex.effective_date_at, 'DD.MM.YYYY', 'unix') }}
                </b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row clss="mt-3" v-if="allowNoteDetails && !isSolicitor">
        <b-col cols="12">

          <b-table-simple
            caption-top
            bordered
            small
            responsive
            no-border-collapse
          >
            <caption><b>{{ $t('label_payments') }}</b></caption>
            <b-thead head-variant="info">
              <b-tr>
                <b-th>{{ $t('label_payment_type') }}</b-th>
                <b-th>{{ $t('label_payment_amount_netto') }}</b-th>
                <b-th>{{ $t('label_payment_amount_gross') }}</b-th>
                <b-th>{{ $t('label_the_monthly_lump_sum_includes_a_limit_in_quantity').ucFirst() }}</b-th>
                <b-th>{{ $t('label_paid_in_cash') }}</b-th>
                <b-th>{{ $t('label_before_each_implementation_on_time') + ' / ' + $t('label_days') }}</b-th>
                <b-th>{{ $t('label_payment_after_the_end_of_the_month_on_time') + ' / ' +
                $t('label_days') }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>

              <b-tr v-for="payment in editedItem.payments">
                <b-td>{{ $t(transPaymentType(payment.type)) }}</b-td>
                <b-td>{{ payment.net_payment ? payment.net_payment.formatPrice() : '--' }}</b-td>
                <b-td>{{ payment.gross_payment ? payment.gross_payment.formatPrice() : '--' }}</b-td>
                <b-td>{{ payment.lump_sum_months ? payment.lump_sum_months : '--' }}</b-td>
                <b-td>{{ payment.pay_in_cash == 1 ? $t('label_yes') : '--' }}</b-td>
                <b-td>{{ payment.pay_for_each_participation == 1 ? parseInt(payment.each_participation_days)
                    : '--' }}
                </b-td>
                <b-td>{{ payment.pay_after_month == 1 ? parseInt(payment.pay_after_month_days) : '--' }}
                </b-td>

              </b-tr>

            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row clss="mt-3" v-if="!isSolicitor">
        <b-col cols="12">

          <b-table-simple
            v-if="editedItem.procurators && editedItem.procurators.length > 0"
            caption-top
            bordered
            small
            responsive
            no-border-collapse
          >
            <caption><b>{{ $t('label_procurators') }}</b></caption>
            <b-thead head-variant="info">
              <b-tr>
                <b-th>{{ $t('label_name_and_surname') }}</b-th>
                <b-th>{{ $t('label_function') }}</b-th>
                <b-th>{{ $t('label_email') }}</b-th>
                <b-th>{{ $t('label_phone') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>

              <b-tr v-for="procurator in editedItem.procurators">
                <b-td>{{ procurator.initials }}</b-td>
                <b-td>{{ procurator.function }}</b-td>
                <b-td>{{ procurator.email }}</b-td>
                <b-td>{{ procurator.phone }}</b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </template>
    <br>

    <annex-modal
      :is-deal="is_deal"
      :edited-item="editedItem"
      :order-subjects="order_subjects"
      @item-added="$emit('refetch-module-item', $event)"
    />
    <deal-status-modal
      :new-status-id="dealStatusId"
      :edited-item="editedItem"
      @item-edited="$emit('refetch-module-item', $event);"
      @close-select="closeSelect()"
      @restore-select-value="dealStatusId = editedItem.deal_status_id;closeSelect();"
    />
  </div>
</template>

<script>
    import {
        BPopover,
        BTableSimple, BTbody, BTr, BTd, BButton, BThead, BTh

    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import annexModal from "../../agreement/modals/annexModal"
    import dealStatusModal from "../modals/processDealStatusModal"
    import {
AGREEMENT_DYNAMIC_STORE_NAME, AGREEMENT_PREFIX, DEAL_PREFIX, transPaymentType
} from "../moduleHelper"

    export default {
        components: {
            BPopover,
            BTableSimple,
            BTbody,
            BTr,
            BTd,
            BButton,
            BThead,
            BTh,
            annexModal,
            dealStatusModal,
            vSelect
        },
        props: ['editedItem', 'relatedDeals'],
        data() {
            return {
                AGREEMENT_DYNAMIC_STORE_NAME,
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                transPaymentType,
                is_deal: true,
                order_subjects: [],
                statuses: [],
                stages: [],
                dealStatusId: this.editedItem? (this.editedItem?.agreement_status?.id || this.editedItem.deal_status_id) : '',
                userData: JSON.parse(localStorage.getItem('userData')),
            }
        },
      computed: {
        isSolicitor() {
            return this.userData?.role === 'solicitor'
        },
        accountNumber() {
          return this.editedItem?.bank_account_number || this.editedItem?.contact?.bank_account_number || ''
        },
        allowNoteDetails() {
          return ['admin', 'consultant', 'consultants'].includes(this.userData.role)
        },
      },
      watch: {
          editedItem: function(newVal, old){
              if(newVal) this.dealStatusId = (this.editedItem?.agreement_status?.id || this.editedItem.deal_status_id);
          }
      },
      created() {
          this.MODULE_PREFIX = this.DEAL_PREFIX;
          this.getPageData();
      },
        methods: {
            getPageData() {
                this.async('get', '/select_options', {params: {options: ['order_subject', 'agreement_statuses']}}, function (resp) {
                    this.order_subjects = resp.data.order_subject;
                    this.statuses = resp.data.agreement_statuses;
                });
            },
          async changeContactStatus(status_id) {
            // const alias = this.statuses.findWhere('id', status_id)?.name_alias
            // if (['198', '199', '200'].includes(status_id)) {
              this.editedDeal = this.editedItem;
              this.newDealStatusId = status_id;
              await this.$bvModal.show('confirm-status-modal');
            // } else {
            //   this.async('put', '/agreement/change_status/' + this.editedItem.id, {status_alias: alias}, function (resp) {
            //   });
            // }
          },
            closeSelect(){
                        const searchEl = this.$refs['status-select'].searchEl;
                        // console.log('searchEl',searchEl);
                        if (searchEl) {
                            setTimeout(function(){ searchEl.blur(); }, 200);

                        }
            }

        },

    }
</script>

<style>
  td {
    word-break: break-word;
  }
</style>
