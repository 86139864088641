var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.PREFIX + '-modal',"hide-footer":""},on:{"hide":function($event){return _vm.resetForm()},"shown":function($event){return _vm.initData()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.action == 'editing'?_vm.$t('label_add_procuration'):_vm.$t('label_add_procuration'))+" ")]},proxy:true},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('validation-observer',{ref:_vm.PREFIX + '_FORM',staticClass:"p-1",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('label_solicitor'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{attrs:{"label":_vm.$t('label_solicitor'),"label-for":"last_name"}},[_c('infinity-scroll',{attrs:{"selected-prop":"id","multiple":false,"url":"/solicitor","placeholder":_vm.$t('label_solicitor'),"default-selection":_vm.chosenSolicitor},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true),model:{value:(_vm.itemData.solicitor_id),callback:function ($$v) {_vm.$set(_vm.itemData, "solicitor_id", $$v)},expression:"itemData.solicitor_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationProps)}},[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)}),_c('label',{staticClass:"mt-1 mb-1"},[_vm._v(_vm._s(_vm.$t('label_specify_the_scope_of_validity_of_the_power_of_attorney')))]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":_vm.$t('label_date_from'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{attrs:{"label":_vm.$t('label_date_from'),"label-for":"meeting_type_id"}},[_c('custom-date-picker',{class:{'date--picker': true , 'is-invalid':_vm.getValidationState(validationProps) === false, 'is-valid':_vm.getValidationState(validationProps) === true},attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.itemData.date_from_at},on:{"input":function($event){_vm.itemData.date_from_at = $event}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}],null,true)},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('feather-icon',{attrs:{"title":"Clear","data-toggle":"","icon":"CalendarIcon","size":"18"}})],1)]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationProps)}},[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)})],1),(_vm.itemData.is_termless == 0)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":_vm.$t('label_date_till'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{attrs:{"label":_vm.$t('label_date_till'),"label-for":"meeting_type_id"}},[_c('custom-date-picker',{class:{'date--picker': true , 'is-invalid':_vm.getValidationState(validationProps) === false, 'is-valid':_vm.getValidationState(validationProps) === true},attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.itemData.date_to_at},on:{"input":function($event){_vm.itemData.date_to_at = $event}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}],null,true)},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('feather-icon',{attrs:{"title":"Clear","data-toggle":"","icon":"CalendarIcon","size":"18"}})],1)]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationProps)}},[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e()],1),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":_vm.$t('label_dont_specify_an_end_date'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{attrs:{"label":""}},[_c('b-form-checkbox',{attrs:{"id":"proc-timeless","value":"1","unchecked-value":"0"},model:{value:(_vm.itemData.is_termless),callback:function ($$v) {_vm.$set(_vm.itemData, "is_termless", $$v)},expression:"itemData.is_termless"}},[_c('label',{attrs:{"for":"proc-timeless"}},[_vm._v(_vm._s(_vm.$t('label_dont_specify_an_end_date')))])])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('button',{staticClass:" btn btn-default",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide(_vm.PREFIX + '-modal')}}},[_vm._v(_vm._s(_vm.$t('label_cancel')))]),_c('button',{staticClass:"btn btn-primary waves-effect waves-light",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit);_vm.focusOnFormError(invalid)}}},[_vm._v(_vm._s((_vm.action == 'editing')?_vm.$t('label_save'):_vm.$t('label_add')))])])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }