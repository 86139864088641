<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            class="align-text-top"
            icon="HomeIcon"
            size="15"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'deals' }">
          {{ $t('label_deals') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t(curPageLabel) }}
        </b-breadcrumb-item>
      </div>
    </div>

    <!--actions panel-->
    <b-card no-body>
      <b-card-body class="tab-tools">
        <div class="text-uppercase mr-1 mb-1">
          <strong>{{ moduleItem.deal_number }}
            <span v-if="moduleItem.firm_name"> ({{ moduleItem.firm_name }})</span>
          </strong>
        </div>

        <b-button
          v-if="$can('edit', MODULE_PREFIX+'.tab_details')"
          variant="outline-primary"
          class="mr-1 "
          :to="{name:MODULE_PREFIX + '_edit', params:{deal_id:moduleItem.id, id:moduleItem.agreement_id}}"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>{{ $t('label_edit') }}</span>
        </b-button>
        <b-button
          v-if="$can('edit', MODULE_PREFIX+'.tab_tasks')"
          variant="outline-primary"
          class="mr-1 "
          @click="$bvModal.show(TASK_PREFIX + '-modal')"
        >
          <feather-icon
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>{{ $t('label_add_task') }}</span>
        </b-button>
        <b-button
          v-if="$can('edit', MODULE_PREFIX+'.tab_working_time_report')"
          variant="outline-primary"
          class=" mr-1"
          @click="$bvModal.show(WORKING_TIME_PREFIX + '-modal')"
        >
          <feather-icon
            icon="ClockIcon"
            class="mr-50"
          />
          <span>{{ $t('label_add_action') }}</span>
        </b-button>

        <b-button
          v-if="$can('edit', MODULE_PREFIX+'.tab_emails')"
          variant="outline-primary"
          class="mr-1"
          @click="$bvModal.show(EMAIL_PREFIX + '-modal')"
        >
          <feather-icon
            icon="MailIcon"
            class="mr-50"
          />
          <span>{{ $t('label_send_email') }}</span>
        </b-button>
        <b-button
          v-if="$can('edit', MODULE_PREFIX+'.tab_sms')"
          variant="outline-primary"
          class=""
          @click="editedSms = false;$bvModal.show(SMS_PREFIX + '-modal')"
        >
          <feather-icon
            icon="BookmarkIcon"
            class="mr-50"
          />
          <span>{{ $t('label_send_sms') }}</span>
        </b-button>
      </b-card-body>
    </b-card>


    <b-card no-body>

      <b-card-body>
        <b-tabs
          :key="$route.params.id"
          content-class="mt-1"
        >
          <!-- This tabs content will always be mounted -->
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_details')"
            class="pt-2"
            :active="curPageLabel == $t('label_informations')"
            :title="$t('label_informations')"
            lazy
            @click="curPageLabel = $t('label_informations')"
          >
            <tab-details
              :edited-item="moduleItem"
              :is-deal="is_deal"
              @refetch-module-item="fetchModuleItem($router.currentRoute.params.id)"
            />
          </b-tab>


          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_notes')"
            :active="curPageLabel == $t('label_deals_tab_notes')"
            :title="$t('label_deals_tab_notes')"
            lazy
            @click="curPageLabel = $t('label_deals_tab_notes')"
          >
            <tab-notes
              :ref="NOTE_PREFIX + '_CONTENT_TAB'"
              :module-item="moduleItem"
              :is-deal="is_deal"
              @edit-item="editedNote = $event"
            />

          </b-tab>

          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_'+TASK_PREFIX+'s')"
            :ref="TASK_PREFIX + '_TAB'"
            :is-deal="is_deal"
            :module-item="moduleItem"
            :active="curPageLabel == $t('label_tasks')"
            :title="$t('label_tasks')"
            lazy
            @click="curPageLabel = $t('label_tasks')"
          >

            <tab-tasks
              :ref="TASK_PREFIX + '_CONTENT_TAB'"
              :module-item="moduleItem"
              :is-deal="is_deal"
              @edit-item="editedTask = $event;"
            />

          </b-tab>

          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_documents')"
            class=""
            :active="curPageLabel == $t('label_documents')"
            :title="$t('label_documents')"
            lazy
            @click="curPageLabel = $t('label_documents')"
          >

            <tab-documents
              :module-item="moduleItem"
              :is-deal="is_deal"
            />

          </b-tab>

          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_payments')"
            ref="agreement_tab"
            class=""
            :active="curPageLabel == ('label_payments')"
            :title="$t('label_payments')"
            lazy
            @click="curPageLabel = $t('label_payments');"
          >

            <tab-payments
              :module-item="moduleItem"
              :is-deal="is_deal"
            />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_rodo')"
            class="pt-2"
            :active="curPageLabel == $t('label_rodo')"
            :title="$t('label_rodo')"
            lazy
            @click="curPageLabel = $t('label_rodo')"
          >

            <tab-rodo
              :module-item="moduleItem"
              :is-deal="is_deal"
            />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_working_time_report')"
            :ref="WORKING_TIME_PREFIX + '_TAB'"
            class=""
            :active="curPageLabel == $t('label_working_time_report')"
            :title="$t('label_working_time_report')"
            lazy
            @click="curPageLabel = $t('label_working_time_report')"
          >

            <tab-working-time-report :module-item="moduleItem" />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_procurations')"
            class=""
            :active="curPageLabel == $t('label_procurations')"
            :title="$t('label_procurations')"
            lazy
            @click="curPageLabel = $t('label_procurations')"
          >

            <tab-procurations :module-item="moduleItem" />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_patterns')"
            class=""
            :active="curPageLabel == $t('label_pattern_generator')"
            :title="$t('label_pattern_generator')"
            lazy
            @click="curPageLabel = $t('label_pattern_generator')"
          >

            <tab-patterns :module-item="moduleItem" />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_emails')"
            class=""
            :active="curPageLabel == $t('label_email')"
            :title="$t('label_email')"
            lazy
            @click="curPageLabel = $t('label_email')"
          >

            <tab-emails
              :ref="EMAIL_PREFIX + '_CONTENT_TAB'"
              :module-item="moduleItem"
              @edit-item="editedEmail = $event;"
            />

          </b-tab>

          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_sms')"
            class=""
            :active="curPageLabel == $t('label_sms')"
            :title="$t('label_sms')"
            lazy
            @click="curPageLabel = $t('label_sms')"
          >
            <tab-sms
              :ref="SMS_PREFIX + '_CONTENT_TAB'"
              module-type="deal"
              :module-item="moduleItem"
              @edit-item="editedSms = $event;"
            />

          </b-tab>
          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_envelope_patterns')"
            class=""
            :active="curPageLabel == $t('label_envelope_generator')"
            :title="$t('label_envelope_generator')"
            lazy
            @click="curPageLabel = $t('label_envelope_generator')"
          >
            <tab-envelop-patterns
              :ref="ENVELOPE_PATTERN_PREFIX + '_CONTENT_TAB'"
              :module-item="moduleItem"
            />

          </b-tab>

          <b-tab
            v-if="$can('view', MODULE_PREFIX+'.tab_history')"
            class=""
            :active="curPageLabel == $t('label_history')"
            :title="$t('label_history')"
            lazy
            @click="curPageLabel = $t('label_history')"
          >

            <tab-history
              :module-item="moduleItem"
              :is-deal="is_deal"
            />

          </b-tab>

        </b-tabs>
      </b-card-body>
    </b-card>

    <process-note-modal
      :is-deal="is_deal"
      :module-item="moduleItem"
      :edited-item="editedNote"
      @item-edited="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
      @item-added="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
    />
    <process-task-modal
      :is-deal="is_deal"
      :module-item="moduleItem"
      :edited-item="editedTask"
      @item-edited="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
      @item-added="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
    />
    <process-email-send-modal
      :module-item="moduleItem"
      :edited-item="editedEmail"
      @item-edited="$refs[EMAIL_PREFIX + '_CONTENT_TAB'] ? $refs[EMAIL_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
      @item-added="$refs[EMAIL_PREFIX + '_CONTENT_TAB'] ? $refs[EMAIL_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
    />
    <process-sms-modal
      module-type="deal"
      :module-item="moduleItem"
      :edited-item="editedSms"
      @item-edited="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
      @item-added="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
    />


  </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import processTaskModal from "../agreement/modals/processTaskModal"
    import processWorkingTimeModal from './modals/processWorkingTimeModal'
    import processEmailSendModal from './modals/processEmailSendModal'
    import processSmsModal from '../agreement/modals/processSmsModal'
    import processNoteModal from "../agreement/modals/processNoteModal"

    import tabDetails from './tabs/tabDetails'
    import tabNotes from "../agreement/tabs/tabNotes"
    import tabTasks from "../agreement/tabs/tabTasks"
    import tabRodo from "../agreement/tabs/tabRodo"
    import tabDocuments from "../agreement/tabs/tabDocuments"
    import tabPayments from "../agreement/tabs/tabPayments"
    import tabWorkingTimeReport from './tabs/tabWorkingTimeReport'
    import tabProcurations from './tabs/tabProcurations'
    import tabHistory from "../agreement/tabs/tabHistory"
    import tabPatterns from './tabs/tabPatterns'
    import tabEmails from './tabs/tabEmails'
    import tabSms from '../agreement/tabs/tabSms'
    import tabEnvelopPatterns from './tabs/tabEnvelopPatterns'

    import {
DEAL_PREFIX as MODULE_PREFIX, NOTE_PREFIX, ENVELOPE_PATTERN_PREFIX,
        TASK_PREFIX, RODO_PREFIX, WORKING_TIME_PREFIX, PATTERN_PREFIX, EMAIL_PREFIX, SMS_PREFIX
} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard,
BCardHeader,
BCardBody,
            BTabs,
BTab,
BAlert,
BButton,

            processTaskModal,
            processNoteModal,
            processWorkingTimeModal,
            processSmsModal,

            tabDetails,
            tabTasks,
            tabNotes,
            tabRodo,
            tabDocuments,
            tabPayments,
            tabWorkingTimeReport,
            tabHistory,
            tabProcurations,
            tabPatterns,
            processEmailSendModal,
            tabEmails,
            tabSms,
            tabEnvelopPatterns
        },
        data() {
            return {

                TASK_PREFIX,
                NOTE_PREFIX,
                RODO_PREFIX,
                WORKING_TIME_PREFIX,
                PATTERN_PREFIX,
                EMAIL_PREFIX,
                MODULE_PREFIX,
                SMS_PREFIX,
                ENVELOPE_PATTERN_PREFIX,

                is_deal: true,

                editedTask:false,
                editedNote:false,
                editedEmail:false,
                editedSms:false,

                curPageLabel: 'label_informations',
                moduleItem: {},
            }
        },
        watch: {
          '$route.params': {
            deep: true,
            handler(newValue, oldValue) {
              if (newValue?.id !== oldValue?.id) {
                this.fetchModuleItem(newValue.id)
              }
            }
          }
        },
        created() {

            this.fetchModuleItem(this.$router.currentRoute.params.id);

        },

        beforeDestroy(){

            this.$store.commit('app/setEditedDeal', false);
        },


        methods: {
            fetchModuleItem(id){
                let self = this;
                this.async('get', '/'+this.MODULE_PREFIX+'/' + id, {}, function (resp) {
                    this.moduleItem = resp.data;
                    this.$store.commit('app/setEditedDeal', this.moduleItem);

                    let alias_route_param =  this.$router.currentRoute.params.alias;
                    if(alias_route_param){
                        this.$nextTick(function(){ self.$refs[alias_route_param+'_TAB']?self.$refs[alias_route_param+'_TAB'].activate(): false });
                    }
                });

            },
            resolveActiveTab(){
                return false;
            }

        },


    }
</script>

