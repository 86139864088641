<template>
    <b-modal
            :id="modalRef? modalRef : PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{action == 'editing'?$t('label_send_email'):$t('label_send_email')}}
        </template>
        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >


                <validation-provider
                        #default="validationProps"
                        :name="$t('label_title')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_title')"

                    >
                        <b-form-input

                                v-model="itemData.title"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider v-if="showAll"

                        #default="validationProps"
                        :name="$t('label_deal_number')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_deal_number')"
                    >

                        <infinity-scroll
                                         v-model="itemData.agreement_id"
                                         selected-prop="id"
                                         primary-key="deal_id"
                                         :has-duplications="true"
                                         url="/deal"
                                         :placeholder="$t('label_select')"
                                         :default-selection="false"
                        >
                            <template #label="{item}">{{$t('label_agreement') + ' ' + item.first_name + ' ' + item.last_name + '(' + (item.number?item.number:item.deal_number) + ')'}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_content')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_content')"

                    >
                        <quill-editor
                                required
                                v-model="itemData.content"
                                :options="snowOption"
                        ></quill-editor>

                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <template v-if="moduleItem || (!moduleItem && itemData.agreement_id)">
                <validation-provider

                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_send_to_contact')"
                        slim
                >
                    <b-form-group>
                        <b-form-checkbox
                                id="e-send-to-contact"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.send_to_contact"
                        >
                            <label for="e-send-to-contact">{{ $t('label_send_to_contact') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_recipients')"
                        rules="required-if-empty:@written_recipients"
                        slim
                        :skip-if-empty="false"
                        vid="chosen_recipients"
                >
                    <b-form-group
                            :label="$t('label_recipients')"
                    >
                        <v-select
                                v-model="itemData.recipients"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="userEmails"
                                :reduce="val => val.id"
                                :clearable="true"
                                multiple
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <!--<span v-if="option.group_label != 'undefined'">{{option.group_label}}sdsd</span>-->
                                <span  class="">{{ option.name + ' ('+$t('label_'+option.role)+')'}}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{option.name}}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>
                </template>
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_recipients')"
                        rules="required-if-empty:@chosen_recipients"
                        :skip-if-empty="false"
                        slim
                        vid="written_recipients"
                >
                    <b-form-group
                            :label="$t('label_recipients')"
                    >
                        <b-form-input
                                :placeholder="$t('label_recipients')"
                                v-model="itemData.emails"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider v-if="moduleItem || itemData.agreement_id"
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_related_docs')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_related_docs')"
                    >
                        <infinity-scroll

                                :query-params="{agreement_id: moduleItem?moduleItem.agreement_id : itemData.agreement_id, extra_search:'default'}"
                                selected-prop="id"
                                :multiple="true"
                                url="/agreement_documents"
                                :placeholder="$t('label_select')"
                                v-model="itemData.deal_docs"

                        >
                            <!--:default-selection="chosenRelDocs"-->
                            <template #label="{item}">{{ item.display_name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <b-row>
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_email_add_task_reminder')"
                                rules=""
                                class="flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_email_add_task_reminder').ucFirst()"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.task_reminder_date"

                                        @input="itemData.task_reminder_date = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_time')"
                                rules=""
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_time')"

                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        placeholder="00:00"
                                        format="HH:mm"
                                        display-format="HH:mm"
                                        v-model="itemData.task_reminder_time"
                                        type="time"
                                        :autoSubmit="true"

                                >
                                    <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                        <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit"
                                                v-text="$t('label_accept')"/>
                                    </template>
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>

                                    <div slot="label">
                                        <feather-icon icon="ClockIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_email_schedule_sending')"
                                rules=""
                                class="flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_email_schedule_sending')"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.send_at_date"

                                        @input="itemData.send_at_date = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_time')"
                                rules=""
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_time')"

                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        placeholder="00:00"
                                        format="HH:mm"
                                        display-format="HH:mm"
                                        v-model="itemData.send_at_time"
                                        type="time"
                                        :autoSubmit="true"

                                >
                                    <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                        <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit"
                                                v-text="$t('label_accept')"/>
                                    </template>
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>

                                    <div slot="label">
                                        <feather-icon icon="ClockIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>


                <validation-provider

                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_to_ocr')"
                        slim
                >
                    <b-form-group
                            label=""
                            label-for="meeting_type_id"
                    >
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                id="m-google_sync"
                                value="1"
                                :disabled="!itemData.send_at_date || !itemData.send_at_time"
                                unchecked-value="0"
                                v-model="itemData.google_sync"
                        >
                            <label for="m-google_sync">{{ $t('label_email_add_google_event') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"

                >

                <file-input
                        v-model="documents"
                        class="file--uploader drop-block mb-3"
                        :max-files-count='10'
                        allowed-extensions="*"
                        :max-file-size='100'
                        :url="''"
                >
                    <template #uploadBody>
                        <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                        <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                      {{$t('label_add_file')}}
                    </template>

                    <template #errorBug="fileErrors">

                        <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </template>
                </file-input>

                </validation-provider>

                <b-progress v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(modalRef? modalRef : PREFIX + '-modal')">
                        {{$t('label_cancel')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_submit'):$t('label_submit')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {requiredAtLeastOne} from '@validations'
    import { quillEditor } from 'vue-quill-editor'

    import {
        BFormTextarea,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BBadge,
        BProgress
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  fileInput from '@/views/components/fileUploadInput'

    import infinityScroll from '@/views/components/infinityScroll'
    import {serialize} from 'object-to-formdata';

    import {DEAL_PREFIX, EMAIL_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {
            quillEditor,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BBadge,
            BProgress,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            fileInput,
            infinityScroll

        },
        props:['moduleItem','editedItem', 'taskDocuments', 'executorsIds', 'modalRef', 'showAll'],
        data() {
            return {

                MODULE_PREFIX: DEAL_PREFIX,
                PREFIX,

                snowOption: {
                    theme: 'snow',
                },
                action: 'adding',

                blankItemData: {
                    id: 0,
                    title: '',
                    content: '',
                    recipients:[],
                    emails:'',
                    agreement_id: null,
                    deal_docs:[],
                    task_reminder_date:null,
                    send_at_date:null,
                    task_reminder_time:null,
                    send_at_time:null,
                    google_sync: 0,
                    send_to_contact:0,
                },

                documents:[],
                itemData: {},

                dealDocs:[],
                userEmails:[],
            }
        },
        watch:{
            moduleItem: function(newVal){
                if(newVal){
                    this.async('get', 'agreement/api_getAgreementExecutors/' + this.moduleItem.agreement_id, {}, function(res){
                        this.userEmails = res.data;
                    });
                }
            }
        },
        methods: {
            initData() {
                if (this.editedItem) {
                    this.action = 'editing';
                    // this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }

            },
            resetForm() {
                this.documents = [];
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData = new FormData();
                this.itemData.google_sync = (!this.itemData.send_at_date || !this.itemData.send_at_time)? 0 : this.itemData.google_sync;

                if(this.moduleItem && this.moduleItem.agreement_id){
                    this.itemData.agreement_id = this.moduleItem.agreement_id;
                }

                formData = serialize(this.itemData, {}, formData);

                if (this.documents.length > 0) {
                    for(let i = 0; i< this.documents.length; i++){
                        formData.append('document[]', this.documents[i]);
                    }
                }


                if (this.action == 'adding') {
                    this.async('post', this.MODULE_PREFIX + '_' + this.PREFIX + 's', formData, function (resp) { //this.itemData.contact_id
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.modalRef? this.modalRef : this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }

            },
            deleteTaskDoc(){
                this.confirmDeleting((result) => {

                    this.async('get', '/'+this.PREFIX+'/'+id+'/delete', {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });

                })

            },
            // getPageData(){
            //
            // }
        },

        // created() {
        //         this.getPageData();
        // }
    }
</script>


<style lang="scss"> //!!! for editor
    @import '@core/scss/vue/libs/quill.scss';
    .ql-editor{
        min-height: 100px;
    }
</style>
