<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
            no-close-on-backdrop
    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit_action'):$t('label_add_action')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_type_of_activity')"
                        rules="required"
                >
                    <b-form-group
                            :label="$t('label_type_of_activity')"
                            :state="getValidationState(validationProps)"
                    >
                        <v-select
                                :disabled="itemData.type == 'free'"
                                v-model="itemData.declarative_time"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="workingTypes"
                                :reduce="val => val.value"
                                label="name"
                                :clearable="false"
                                :placeholder="$t('label_select')"

                        >
                            <template v-slot:option="option">
                                <span class="">{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <!--<span :class="option.icon"></span>-->
                                <span class="">{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="itemData.type != 'free'"
                        #default="validationProps"
                        :name="$t('label_deal_number')"
                        :rules="itemData.is_global? '' : 'required'"
                >
                    <b-form-group
                            :label="$t('label_deal_number')"
                            :state="getValidationState(validationProps)"
                    >
                        <!--:close-on-select="false"-->
                        <infinity-scroll
                                selected-prop="deal_id"
                                :multiple="false"
                                url="/deal"
                                :placeholder="$t('label_select')"
                                v-model="itemData.deal_id"
                                :default-selection="chosenDeal"
                                :disabled="timerAction == 'complete'"
                        >
                            <template #label="{item}">{{(item.deal_number? item.deal_number : item.number) }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <b-row v-if="itemData.declarative_time == 1">
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_term')"
                                rules="required"
                                class="flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_term')"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.time_report_date"

                                        @input="itemData.time_report_date = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_time')"
                                rules="required"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_time')"

                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        placeholder="00:00"
                                        format="HH:mm"
                                        display-format="HH:mm"
                                        v-model="itemData.time_report_minutes"
                                        type="time"
                                        :autoSubmit="true"

                                >
                                    <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                        <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit"
                                                v-text="$t('label_accept')"/>
                                    </template>
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>

                                    <div slot="label">
                                        <feather-icon icon="ClockIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <validation-provider v-if="itemData.declarative_time == 1 || action == 'editing'"
                        #default="validationProps"
                        :name="$t('label_execution_time_activities')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_execution_time_activities')"

                    >
                        <custom-date-picker
                                :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                placeholder="00:00"
                                format="HH:mm"
                                display-format="HH:mm"
                                v-model="itemData.worked_minutes"
                                type="time"
                                :autoSubmit="true"

                        >
                            <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit"
                                        v-text="$t('label_accept')"/>
                            </template>
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>

                            <div slot="label">
                                <feather-icon icon="ClockIcon" size="18"/>
                            </div>
                        </custom-date-picker>


                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_name_of_the_activity')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_name_of_the_activity')"
                            label-for="n-title"
                    >
                        <b-form-input
                                id="n-title"
                                v-model="itemData.name_activity"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_subject')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <div class="d-flex justify-content-end mt-2">
                    <b-button v-if="timerAction == 'complete'"
                            id="delete-all-target"
                            variant="outline-danger"
                            @click="deleteTracker()"

                            class="mr-auto btn-icon"
                    >
                        <feather-icon

                                size="18"
                                icon="Trash2Icon"
                        />
                    </b-button>
                    <!--<button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>-->

                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_cancel')}}</button>

                    <b-button variant="primary" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class=" bg-primary mr-1">{{(timerAction == 'complete')?$t('label_save'):$t('label_submit')}}</b-button>

                    <b-button v-if="timerAction == 'complete'" @click.prevent="handleSubmit(onSubmit(true));focusOnFormError(invalid)" type="submit"
                            class="bg-primary bg-darken-1">{{$t('label_save_and_finish')}}</b-button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>
<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
       BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend, BButton
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import  infinityScroll from '@/views/components/infinityScroll'

    import {AGREEMENT_DYNAMIC_STORE_NAME,  WORKING_TIME_PREFIX as PREFIX, DEAL_PREFIX, workingTypes} from './../moduleHelper'

    export default {
        components: {

           BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend, BButton,

            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll
        },
        props:['editedItem', 'timerAction'],
        data() {
            return {

                PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX:'',

                workTimer:{},

                action: 'adding',

                blankItemData: {
                    id:0,
                    declarative_time:1,
                    deal_id:null,
                    time_report_date: '',
                    time_report_minutes: '00:00',
                    worked_minutes: '00:00',
                    name_activity: '',
                    is_global:0,
                    type: 'agreement', // agreement \ free \ agreement_task
                    status: '',
                    ['timer-action']:this.timerAction
                },

                itemData: {},

                chosenDeal:false,

               workingTypes,

               wkTimeOptions: false,
            }
        },

        methods: {
            initData(){

                let  itemFromStore = this.$store.state.app.editedWorkingTime;

                if(itemFromStore){
                    Object.assign({},this.$store.state.app.editedWorkingTime);
                    this.action = 'editing';

                    let item = Object.assign({}, this.blankItemData);
                    item.id = itemFromStore.id;
                    for (let prop in item) {
                        if (itemFromStore.hasOwnProperty(prop)) {
                            item[prop] = itemFromStore[prop];
                        }
                    }

                    item.time_report_date = formatDate(itemFromStore.executed_date, 'DD/MM/YYYY', 'unix');
                    item.time_report_minutes = formatDate(itemFromStore.executed_date, 'HH:mm', 'unix');
                    item.worked_minutes = formatSeconds(itemFromStore.worked_time_seconds, 'HH:mm');
                    item.declarative_time = parseInt(item.declarative_time);

                    this.itemData = item;

                } else {
                    // if(this.timerAction && this.timerAction != 'run_new){
                    //     this.action = 'editing';
                    // } else {
                        this.action = 'adding';
                        this.itemData =  Object.assign({},this.blankItemData);
                    // }
                }

                let dealFromStore = this.$store.state.app.editedDeal;

                if(!!dealFromStore) {

                    dealFromStore.deal_id = dealFromStore.id;

                    this.chosenDeal = dealFromStore;
                    // console.log(dealFromStore);
                   if(this.action == 'adding'){
                       this.itemData.deal_id = dealFromStore.id;
                   }
                }

                this.wkTimeOptions = this.$store.state.app.wkTimeModalOptions;

                if(this.wkTimeOptions) {
                    if(this.wkTimeOptions.type){
                        this.itemData.type = this.wkTimeOptions.type;
                    }
                    if(this.wkTimeOptions.declarative_time) {
                        this.itemData.declarative_time = this.wkTimeOptions.declarative_time;
                    }
                    if(this.wkTimeOptions.relatedModuleItem) {
                        this.chosenDeal = dealFromStore;
                    }
                }

                let working_data = this.workTimer.getTimerData();


                if(this.timerAction ){

                    this.itemData.is_global = 1;

                    if(working_data) {

                        if(working_data.name_activity) {
                            this.itemData.name_activity = working_data.name_activity;
                        }
                        if(working_data.activity_type) {
                            this.itemData.declarative_time = parseInt(working_data.activity_type);
                        }
                        if(working_data.deal_id && !working_data.task_id && !this.chosenDeal){
                            this.itemData.deal_id = working_data.deal_id;
                            this.getChosenDeal(working_data.deal_id);
                        }
                    }

                }
                if(working_data && working_data.deal_id && working_data.task_id && !this.chosenDeal) { //task_edit
                    this.itemData.deal_id = working_data.deal_id;
                    this.itemData.declarative_time = 2;
                  this.getChosenDeal(working_data.deal_id);
                } else if(this.action == 'editing') {

                    if(!this.itemData.task_id && !this.itemData.deal_id) {
                        this.itemData.type = 'free';
                    } else if(!this.itemData.task_id && this.itemData.deal_id && !this.chosenDeal){
                        this.getChosenDeal(this.itemData.deal_id);
                    } else if(this.itemData.task_id){
                        this.itemData.type = 'agreement_task';
                    }
                }

                this.itemData['timer-action'] = this.timerAction? this.timerAction : 'adding_new';

            },
            getChosenDeal(deal_id){
                this.async('get', '/deal/' + deal_id, {}, function (resp) {
                    let deal = resp.data;
                    deal.deal_id = resp.data.id;
                    this.chosenDeal = deal;
                });
            },
            resetForm() {
                this.$store.state.app.wkTimeModalOptions = false;
                this.$emit('clear-action');
                this.$store.state.app.editedWorkingTime = false;
                this.itemData = Object.assign({},this.blankItemData);
                this.chosenDeal = false;
                this.$refs[this.PREFIX + '_FORM'].reset();
            },

            onSubmit(moveToNotCalculated){

                let working_data = this.workTimer.getTimerData();
                working_data.name_activity = this.itemData.name_activity;

                if(this.timerAction == 'complete'){

                    this.workTimer.updateInnerTimerData(working_data);
                    this.workTimer.stopTimer(true, true, moveToNotCalculated);

                    this.$bvModal.hide(this.PREFIX + '-modal');

                    this.refreshWorkingTab(moveToNotCalculated? 'declarative_time' : 'real_time' );

                    if(working_data.task_id > 0){
                        this.async('put', '/agreement_tasks/finish/' + working_data.task_id, {}, function (resp) {
                            if(this.$root.$refs['task_CONTENT_TAB']){
                                this.$root.$refs['task_CONTENT_TAB'].refreshDataTable();
                            }
                        });
                    }

                    return; //exit
                }

                this.itemData.status = this.itemData.declarative_time;

                if(this.action == 'adding') {
                    if(this.itemData.declarative_time == 2){
                        if (!this.workTimer.isTimerReset()) {
                            return this.showToast('warning', this.$t('label_complete_the_current_task'));
                        }
                    }else if(this.itemData.declarative_time == 1 && !this.itemData.deal_id) {
                        this.itemData.type = 'free';
                    }
                    this.async('post', this.PREFIX + '/api_process_working_time_report', this.itemData, function (resp) {

                        if (this.itemData.declarative_time == 2) {
                            this.workTimer.displayTimer();
                            if(this.action != 'complete_and_move_to_not_calc'){
                                this.refreshWorkingTab('real_time');
                            } else {
                                this.refreshWorkingTab('declarative_time');
                            }

                        } else if (this.itemData.declarative_time == 1 && this.$root.$refs[this.PREFIX+'_TAB']) {
                            this.$root.$refs[this.PREFIX+'_TAB'].displayTable('declarative_time');
                        } else {
                            this.refreshWorkingTab('real_time');
                        }
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    if(this.itemData.declarative_time == 2){
                        this.async('put', this.PREFIX + '/workedtime/'+this.itemData.id, this.itemData, function(resp){

                            this.$bvModal.hide(this.PREFIX + '-modal');
                            this.refreshWorkingTab('declarative_time');

                        });
                    } else {
                        this.async('put', this.PREFIX + '/api_process_working_time_report/'+this.itemData.id, this.itemData, function(resp){

                            this.$bvModal.hide(this.PREFIX + '-modal');
                            // if(this.itemData.declarative_time == 2) {
                            //     this.refreshWorkingTab('real_time');
                            // } else {
                                this.refreshWorkingTab('declarative_time');
                            // }

                        });
                    }
                }
            },

            deleteTracker(){
                this.confirmDeleting((result) => {

                    let timerData = this.workTimer.getTimerData();

                    if (timerData && timerData.client_working_time_id) {
                        this.workTimer.resetTimer(true, timerData.client_working_time_id);
                    } else {
                        this.workTimer.resetTimer(true);
                    }
                    this.$bvModal.hide(this.PREFIX + '-modal');
                    this.refreshWorkingTab('real_time');
                    this.$emit('clear-action');

                })
            },

            refreshWorkingTab(sort){
                if(this.$root.$refs[this.PREFIX+'_TAB']){

                    if(this.$root.$refs[this.PREFIX+'_TAB'].displayed_table == sort) {
                        this.$root.$refs[this.PREFIX+'_TAB'].refreshDataTable();
                    } else {
                        this.$root.$refs[this.PREFIX+'_TAB'].displayed_table = sort;
                    }
                }
            }
        },

        created() {
            this.workTimer = this.$parent;

        },

    }
</script>
