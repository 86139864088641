<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit_note'):$t('label_add_new')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >


                <validation-provider

                        #default="validationProps"
                        :name="$t('label_address')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_address')"

                    >
                        <b-form-textarea
                                rows="5"
                                :state="getValidationState(validationProps)"
                                v-model="itemData.sender_address"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_addressee')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_addressee')"

                    >
                        <b-form-textarea
                                :state="getValidationState(validationProps)"
                                v-model="itemData.recipient_address"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_envelope_template')"
                        rules="required-if-empty:@written_recipients"
                        slim
                        :skip-if-empty="false"
                        vid="chosen_recipients"
                >
                    <b-form-group
                            :label="$t('label_envelope_template')"
                    >
                        <v-select
                                v-model="itemData.template_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="envelopeTemplates"
                                :reduce="val => val.id"
                                :clearable="true"
                                :multiple="false"
                                :close-on-select="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <!--<span v-if="option.group_label != 'undefined'">{{option.group_label}}sdsd</span>-->
                                <span  class="">{{ option.name }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{option.name}}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_generate_envelope')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BInputGroupPrepend,
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import { ENVELOPE_PATTERN_PREFIX as PREFIX, DEAL_PREFIX as MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        props: ['moduleItem', 'editedItem'],
        data() {
            return {

                MODULE_PREFIX: 'deal',
                PREFIX,

                action: 'adding',

                blankItemData: {

                    sender_address: '',
                    recipient_address: '',
                    deal_id:'',
                    template_id:''
                },

                itemData: {},

                envelopeTemplates: []
            }
        },
        watch: {
            moduleItem: function (moduleItem) {
                if (moduleItem) {
                    // this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
                    this.blankItemData['deal_id'] = moduleItem.deal_id;
                }
            }
        },
        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
                this.async('get', '/invoice_details' , {params:{}}, function(res){
                    this.invoiceData = res.data[0];

                    this.itemData.sender_address = this.invoiceData.company +
                        '\n' + this.invoiceData.address +
                        // '\n ' + 'NIP: ' +this.invoiceData.nip +
                        '\n' + this.$t('label_post_code')+': ' +this.invoiceData.post_code;
                        // '\n ' + 'KRS: ' +this.invoiceData.krs;

                });
                this.async('get', '/select_options', {params:{options:['envelope_templates']}}, function(res){
                    this.envelopeTemplates = res.data.envelope_templates;
                });
                this.itemData.recipient_address = this.moduleItem.first_name + ' ' + this.moduleItem.last_name
                    + '\n' + this.moduleItem.address_correspondence;

            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                this.itemData.deal_id = this.moduleItem.id;
                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's', this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }

            }
        },
        created(){

        }

    }
</script>